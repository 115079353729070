import React from "react";
import { v4 as uuid } from "uuid";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";
import FormControl from "react-bootstrap/FormControl";
import { useAuth } from "state/auth";
import { camelCaseToTitle } from "../../theme/helpers/utils";
import { useModalContext } from "state/modal";
import { gettext } from "i18n";
import { PickerTemplate, WIDGET_TEMPLATES } from "utils/widgets";
import "./AddWidgetForm.scss";

interface AddWidgetFormProps {
  stationIdentifier: string;
  editedStructure: any[] | null;
  setEditedStructure: React.Dispatch<React.SetStateAction<any[] | null>>;
  setChangesMade: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddWidgetForm = ({
  setChangesMade,
  editedStructure,
  stationIdentifier,
  setEditedStructure,
}: AddWidgetFormProps) => {
  const { setActiveModal } = useModalContext();
  const onHide = () => setActiveModal(null);
  const { user } = useAuth();
  const isUserAdmin = user?.admin;
  const defaultValues = {
    widgetTemplateKey: "",
    metaData: {},
  };
  const [editedValues, setEditedValues] =
    React.useState<Record<string, any>>(defaultValues);
  const [modalDisableSave, setModalDisableSave] =
    React.useState<boolean>(false);
  const { widgetTemplateKey: selectedKey } = editedValues;

  const resetEditedValues = () => {
    setEditedValues(defaultValues);
  };

  const closeModal = () => {
    resetEditedValues();
    setModalDisableSave(true);
    onHide();
  };

  const selectedTemplateData = WIDGET_TEMPLATES.find(
    (widgetTemplate) => widgetTemplate.key === selectedKey
  );
  const showWidgetParams = !!Object.keys(selectedTemplateData?.metaData || {})
    .length;
  const widgetPickerList: any[] = (selectedTemplateData?.pickers ??
    []) as any[];
  const requiredFields: string[] = (selectedTemplateData?.requiredFields ??
    []) as any[];
  const showWidgetPickers = !!widgetPickerList.length;
  const showWidgetParamsOrPickers = showWidgetParams || showWidgetPickers;

  const missingRequiredValues = requiredFields?.filter(
    (field: string) =>
      !editedValues.metaData[field] ||
      editedValues.metaData[field] ===
        (((selectedTemplateData?.metaData as any)?.[field] as any) ?? "")
  );

  const saveEditedStructure = async () => {
    if (missingRequiredValues?.length) {
      const missingFieldDisplay = missingRequiredValues
        .map((field) => `"${camelCaseToTitle(field)}"`)
        .join(", ");
      toast.error(
        gettext("Please fill in the required fields: ") + missingFieldDisplay
      );
      return;
    }
    if (!selectedKey) {
      toast.error(gettext("Please select a valid widget template"));
      return;
    }

    const templateData: any = WIDGET_TEMPLATES.find(
      (widgetTemplate) => widgetTemplate.key === selectedKey
    );
    if (!templateData) {
      toast.error(gettext("Please select a valid widget template"));
      return;
    }
    const { metaDataParsers = {} } = templateData;
    const parsedMetaData = Object.entries(editedValues.metaData).reduce(
      (acc, [key, value]) => {
        const parser: (raw: string | string[]) => any = metaDataParsers[key];
        return {
          ...acc,
          [key]: parser ? parser(value as any) : value,
        };
      },
      {}
    );

    const widgetId = uuid();
    const newWidget = {
      key: selectedKey,
      metaData: {
        widgetId: widgetId,
        ...parsedMetaData,
      },
    };

    // Add a new row containing newWidget to the end of the list of widget_rows in editedStructure

    const newEditedStructure = [...(editedStructure ?? [])];
    const stationIndex = newEditedStructure.findIndex(
      (station) => station.station.identifier === stationIdentifier
    );
    if (stationIndex === -1) {
      toast.error(
        gettext("Something went wrong, please refresh the page and try again")
      );
      return;
    }
    const station = newEditedStructure[stationIndex];
    const newWidgetRows = [...(station.station.widget_rows ?? [])];
    newWidgetRows.push({
      widgets: [newWidget],
    });
    const newStation = {
      ...station,
      station: {
        ...station.station,
        widget_rows: newWidgetRows,
      },
    };
    newEditedStructure[stationIndex] = newStation;
    setEditedStructure(newEditedStructure);
    setChangesMade(true);
    closeModal();
  };

  return (
    <Modal
      show
      onHide={closeModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Widget</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive>
          <tbody>
            <tr>
              <th>Widget Type</th>
              <td>
                <Form.Select
                  aria-label="Widget Type"
                  onChange={(e: any) => {
                    resetEditedValues(); // Do not keep metaData from other widget types
                    const newTemplateKey = e.target.value;
                    const newTemplateData = (WIDGET_TEMPLATES.find(
                      (widgetTemplate) => widgetTemplate.key === newTemplateKey
                    ) ?? {}) as any;

                    setEditedValues({
                      ...editedValues,
                      widgetTemplateKey: newTemplateKey,
                      metaData: {
                        ...newTemplateData.metaData,
                      },
                    });
                  }}
                  value={editedValues.widgetTemplateKey ?? ""}
                >
                  <option value="">Select widget type</option>
                  {WIDGET_TEMPLATES?.filter(
                    (widgetTemplate) => isUserAdmin || !widgetTemplate.adminOnly
                  ).map((widgetTemplate) => (
                    <option
                      key={`widget_template_${widgetTemplate.key}`}
                      value={String(widgetTemplate.key)}
                    >
                      {gettext(String(widgetTemplate.name))}
                    </option>
                  ))}
                </Form.Select>
              </td>
            </tr>
            {showWidgetParamsOrPickers && (
              <tr>
                <th colSpan={2}>Widget parameters:</th>
              </tr>
            )}
            {showWidgetParams && (
              <>
                {Object.entries(selectedTemplateData?.metaData || {}).map(
                  ([key, value]) => (
                    <tr key={`widget_template_metadata_${key}`}>
                      <th>{camelCaseToTitle(key)}</th>
                      <td>
                        <FormControl
                          type="text"
                          required={requiredFields?.includes(key) ?? false}
                          value={editedValues.metaData[key] ?? ""}
                          placeholder={value || gettext("(optional)")}
                          onChange={(e: any) => {
                            const value = e.target.value;
                            setEditedValues({
                              ...editedValues,
                              metaData: {
                                ...editedValues.metaData,
                                [key]: value,
                              },
                            });
                          }}
                        />
                      </td>
                    </tr>
                  )
                )}
              </>
            )}
            {showWidgetPickers && (
              <>
                {widgetPickerList.map((picker: Record<string, any>) => {
                  const PickerInstance = (
                    <PickerTemplate
                      pickerTemplate={picker.key}
                      onChange={(e: any) => {
                        const value = e.target.value;
                        setEditedValues({
                          ...editedValues,
                          metaData: {
                            ...editedValues.metaData,
                            [picker.field]: value,
                          },
                        });
                      }}
                      filters={picker.filters}
                      field={picker.field}
                      params={picker.params}
                      value={editedValues?.metaData?.[picker.field] ?? ""}
                      editedValues={editedValues}
                      setEditedValues={setEditedValues}
                    />
                  );
                  return (
                    <React.Fragment
                      key={`widget_template_picker_${picker.key}`}
                    >
                      {picker.label ? (
                        <tr>
                          <th>{gettext(picker.label)}</th>
                          <td>{PickerInstance}</td>
                        </tr>
                      ) : (
                        PickerInstance
                      )}
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            onHide();
          }}
        >
          Close
        </Button>
        <Button
          type="button"
          onClick={saveEditedStructure}
          disabled={modalDisableSave}
        >
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddWidgetForm;
