import React from "react";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { gettext } from "i18n";
import { copyToClipBoard } from "theme/helpers/utils";
import { useModalContext } from "state/modal";
import {
  loadManagedOrganizations,
  ManagedOrganizationOption,
} from "utils/users";

import "./AddAuthTokenForm.scss";

interface AddAuthTokenFormProps {
  show: boolean;
  newToken: string;
  setNewToken: React.Dispatch<React.SetStateAction<string>>;
  onAddToken: (description: string, organizationIds: number[]) => void;
}

const AddAuthTokenForm: React.FC<AddAuthTokenFormProps> = ({
  show,
  newToken,
  setNewToken,
  onAddToken,
}: AddAuthTokenFormProps) => {
  const { setActiveModal } = useModalContext();
  const onHide = () => {
    setActiveModal(null);
    setNewToken("");
  };
  const tokenInputRef = React.useRef(null);
  const [validated, setValidated] = React.useState<boolean>(false);
  const [description, setDescription] = React.useState<string>("");
  const [viewedOrgId, setViewedOrgId] = React.useState<number | null>(null);
  const [organizationIds, setOrganizationIds] = React.useState<number[]>([]);

  const [managedOrganizations, setManagedOrganizations] = React.useState<
    ManagedOrganizationOption[]
  >([]);

  const organizationRefetch = () =>
    loadManagedOrganizations({
      setManagedOrganizations,
    });

  React.useEffect(() => {
    organizationRefetch();
  }, [setManagedOrganizations]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    // Show any potential input errors
    setValidated(true);

    // If the form is valid, submit
    const form = event.currentTarget;

    if (form.checkValidity()) {
      onAddToken(description, organizationIds);
      setValidated(false);
    }
  };

  const handleCopyText = () => {
    copyToClipBoard(tokenInputRef);
    toast.success(gettext("Value copied to clipboard"), {
      theme: "colored",
    });
  };

  const availableOrganizations = managedOrganizations.filter(
    (organization) => !organizationIds.includes(organization.id)
  );

  return (
    <Modal
      animation
      show={show}
      onHide={() => {
        setValidated(false);
        onHide();
        setOrganizationIds([]);
        setDescription("");
        setViewedOrgId(null);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add new authentication token
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} onSubmit={handleSubmit}>
          <Table>
            <tbody>
              {!!newToken && (
                <tr>
                  <td>
                    <p>
                      <strong>WARNING:</strong> This token is only visible once.
                      If you lose it, you will need to generate a new one.
                    </p>
                    <Form.Group>
                      <Form.Label>Token</Form.Label>
                      <div className="tokenRow">
                        <Form.Control
                          ref={tokenInputRef}
                          type="text"
                          placeholder="Token"
                          value={newToken}
                          readOnly
                        />
                        <Button size="sm" onClick={handleCopyText}>
                          Copy
                        </Button>
                      </div>
                    </Form.Group>
                  </td>
                </tr>
              )}
              {!newToken && (
                <>
                  <tr>
                    <td>
                      <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </Form.Group>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Group>
                        <Form.Label>Accessible organizations</Form.Label>
                        {!!organizationIds.length && (
                          <Form.Group>
                            <ul>
                              {organizationIds.map((orgId) => {
                                const organization = managedOrganizations.find(
                                  (org) => org.id === orgId
                                );
                                return (
                                  <li key={`org_${orgId}`}>
                                    {organization?.name}
                                  </li>
                                );
                              })}
                            </ul>
                          </Form.Group>
                        )}
                        <Form.Select
                          aria-label="Organization accessible by the token"
                          onChange={(e) => {
                            setViewedOrgId(parseInt(e.target.value));
                          }}
                          value={viewedOrgId ?? ""}
                          disabled={!availableOrganizations.length}
                        >
                          <option></option>
                          {availableOrganizations.map((organization) => (
                            <option
                              key={`org_${organization.id}`}
                              value={organization.id}
                            >
                              {organization.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <Button
                        onClick={() => {
                          setOrganizationIds([
                            ...organizationIds,
                            viewedOrgId as number,
                          ]);
                          setViewedOrgId(null);
                        }}
                        disabled={!viewedOrgId}
                      >
                        Add organization
                      </Button>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>

          <div className="actionButtons">
            <Button variant="light" onClick={onHide}>
              Close
            </Button>
            {!newToken && <Button type="submit">Save</Button>}
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddAuthTokenForm;
